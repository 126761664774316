import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Link as Scroll } from "react-scroll"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Zoom from "react-medium-image-zoom"
import useMedia from "use-media"

import "react-medium-image-zoom/dist/styles.css"
import { Waypoint } from "react-waypoint"
import { UTIL, COLORS, FONT } from "../util/constants"

const IndexPage = () => {
  const [isOpenArray, setIsOpenArray] = React.useState([])
  const [goThroughWaypoint, setGoThroughWaypoint] = React.useState(false)
  const isTabletOrMobile = useMedia({ maxWidth: "700px" })

  const handleSetIsOpenArray = React.useCallback(
    e => {
      if (isOpenArray.includes(e.target.innerText)) {
        setIsOpenArray(isOpenArray.filter(s => s !== e.target.innerText))
      } else {
        setIsOpenArray([...isOpenArray, e.target.innerText])
      }
    },
    [isOpenArray]
  )

  return (
    <Layout>
      <Top>
        <Scroll to="top" smooth={true} duration={400}>
          <span>top</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 16"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          >
            <path d="M29 15L15 1M1 15L15 1" />
          </svg>
        </Scroll>
      </Top>
      <Seo
        title="みゆき塗料商会"
        description="創立以来自動車補修に特化したオートサプライヤーとして営業してまいりました。
        車の進歩は目を見張るものがあると同時に、各メーカーの自動車用塗料、副資材、機器工具も進歩し続けています。
        お客様には情報や技術の提供を伝えると同時に研修の機会を設け共に成長できる場として研修設備を備えた工場も併設しています。
        但、最後は人の手と目による見極めが重要です。私たちは長年の経験と知識をもって、その見極めのお手伝いができると自信を持って日々努力を重ねております。"
      />
      <HeroSectionWrap>
        <HeroSection>
          <StaticImage
            src="../images/hero.jpg"
            alt="みゆき塗料商会メイン画像"
            formats={["AUTO", "PNG", "AVIF"]}
            placeholder="blurred"
            layout="fullWidth"
            quality={100}
          />
        </HeroSection>
      </HeroSectionWrap>
      <Divider />
      <DescriptionSection>
        <BaseContainer>
          <Waypoint
            onEnter={() => {
              setGoThroughWaypoint(true)
            }}
          >
            <p className={goThroughWaypoint ? "fade-in-image" : ""}>
              創立以来自動車補修に特化したオートサプライヤーとして営業してまいりました。
              <br className="sp_hide_br" />
              車の進歩は目を見張るものがあると同時に、
              <br className="sp_hide_br" />
              各メーカーの自動車用塗料、副資材、機器工具も進歩し続けています。
              <br />
              お客様には情報や技術の提供を伝えると同時に研修の機会を設け共に成長できる場として
              <br className="sp_hide_br" />
              研修設備を備えた工場も併設しています。
              <br />
              但、最後は人の手と目による見極めが重要です。私たちは長年の経験と知識をもって、
              <br className="sp_hide_br" />
              その見極めのお手伝いができると自信を持って日々努力を重ねております。
            </p>
          </Waypoint>
        </BaseContainer>
      </DescriptionSection>

      <BgBlueGradation>
        <ContentSection>
          <BaseContainer>
            <H2 id="content">業務内容</H2>
            <p className="line_height_default">
              各メーカーの自動車用塗料、
              <br className="pc_hide_br" />
              副資材、機器工具の販売
            </p>
          </BaseContainer>
        </ContentSection>

        <ContentSection>
          <BaseContainer>
            <H2 id="maker">取扱いメーカー及び企業</H2>
            <BaseContainerMaker>
              <C3C2>
                <H3>塗料</H3>
                <ul>
                  <li>
                    <a
                      href="https://www.nipponpaint.co.jp/products/automotive/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      日本ペイント株式会社
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.rmpaint.com/jp/welcome-r-m"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      BASFジャパン株式会社
                    </a>
                  </li>
                  <li>関西ペイント株式会社</li>
                  <li>株式会社シモダ</li>
                </ul>
              </C3C2>
              <C3C2>
                <H3>カスタムペイント</H3>
                <ul>
                  <li>
                    <a
                      href="https://www.showup.jp/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      株式会社シグナル　SHOW-UP
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.aiwa-c.co.jp/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      株式会社アイワ
                    </a>
                  </li>
                </ul>
              </C3C2>
              <C3C2>
                <H3>溶剤</H3>
                <ul>
                  <li>日東化成株式会社</li>
                </ul>
              </C3C2>
              <C3C2>
                <H3>副資材</H3>
                <ul>
                  <li>スリーエムジャパン株式会社</li>
                  <li>株式会社コバックス</li>
                  <li>三共理化学株式会社</li>
                  <li>株式会社スズキ</li>
                  <li>大塚刷毛製造株式会社</li>
                  <li>ゆたか磨材株式会社</li>
                  <li>庄司株式会社</li>
                  <li>ロック商事株式会社</li>
                </ul>
              </C3C2>
              <C3C2>
                <H3>塗装機器</H3>
                <ul>
                  <li>株式会社海南</li>
                  <li>アネスト岩田株式会社</li>
                  <li>CFTランズバーグ株式会社</li>
                  <li>明治機械製作所</li>
                  <li>株式会社恵宏製作所</li>
                  <li>株式会社ダイイチ</li>
                </ul>
              </C3C2>
              <C3C2>
                <H3>溶接機器</H3>
                <ul>
                  <li>株式会社ヤシマ</li>
                  <li>株式会社栄和製作所</li>
                  <li>デンゲン株式会社</li>
                  <li>株式会社ヒラネ</li>
                </ul>
              </C3C2>
              <C3C2>
                <H3>設備機器</H3>
                <ul>
                  <li>アンデックス株式会社</li>
                  <li>株式会社イチネンMTM</li>
                  <li>栗田工業株式会社</li>
                  <li>ネキスト株式会社</li>
                </ul>
              </C3C2>
              <C3C2>
                <H3>コーティング</H3>
                <ul>
                  <li>
                    <a
                      href="https://www.soft99-as.co.jp/corp_site/service_general/bodycoating.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      株式会社ソフト99コーポレーション（ガラスコーティング剤）
                    </a>
                  </li>
                </ul>
              </C3C2>
              <C3C2>
                <H3>見積もり</H3>
                <ul>
                  <li>コグニビジョン株式会社</li>
                  <li>株式会社プロトリオス</li>
                  <li>&nbsp;</li>
                  <li style={{ textAlign: "right" }}>等</li>
                </ul>
              </C3C2>
            </BaseContainerMaker>
          </BaseContainer>
        </ContentSection>

        <ContentSection>
          <BaseContainer>
            <H2 id="service">サービス内容のご紹介</H2>
            <BaseContainerToggle isOpenArray={isOpenArray}>
              <h3
                onClick={e => {
                  handleSetIsOpenArray(e)
                }}
              >
                作業環境測定
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 37 19.5"
                  fill="none"
                  stroke="#00b4e5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className={
                    isOpenArray?.includes("作業環境測定") ? "toggle1" : ""
                  }
                >
                  <path d="M1 1l17.5 17.5M36 1L18.5 18.5" />
                </svg>
              </h3>
              <BaseContainerToggleC
                className={
                  isOpenArray?.includes("作業環境測定") ? "toggle1" : ""
                }
              >
                <dl>
                  <dt>アサヒ産業環境株式会社</dt>
                  <dd>
                    塗装時に発生する有機溶剤・特定化学物質を測定、ブース機能が十分に働いているか検証し、
                    <br className="sp_hide_br" />
                    報告書の元に作業環境の保全につなげることで労働基準監督署にも対応
                    <br />
                    （※労働安全衛生法第28条第3項の規定に基づく）
                  </dd>
                </dl>
                <dl>
                  <h4>ブースメンテナンス</h4>
                  <dt>宮城野工業株式会社</dt>
                  <dd>
                    塗装ブース・局所排気装置・ダクト等清掃及び吸排気フィルター交換、法定自主点検及び報告書作成
                  </dd>
                </dl>
              </BaseContainerToggleC>
            </BaseContainerToggle>
          </BaseContainer>
          <BaseContainer>
            <BaseContainerToggle isOpenArray={isOpenArray}>
              <h3
                onClick={e => {
                  handleSetIsOpenArray(e)
                }}
              >
                工場診断
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 37 19.5"
                  fill="none"
                  stroke="#00b4e5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className={isOpenArray?.includes("工場診断") ? "toggle2" : ""}
                >
                  <path d="M1 1l17.5 17.5M36 1L18.5 18.5" />
                </svg>
              </h3>
              <BaseContainerToggleC
                className={isOpenArray?.includes("工場診断") ? "toggle2" : ""}
                style={{ backgroundColor: "white" }}
              >
                <h4>
                  お客様の生産性向上と安全で快適な鈑金塗装工場づくりを支援する
                </h4>
                <p className="line_height_default">
                  日本ペイント株式会社認定のインストラクターとして
                  <br className="sp_hide_br" />
                  カーディーラー内製化工場や協力工場を診断して参りました。
                </p>
              </BaseContainerToggleC>
              <BaseContainerToggleC
                className={isOpenArray?.includes("工場診断") ? "toggle2" : ""}
              >
                <dl>
                  <h4>内容</h4>
                  <dt>定期改善ミーティングの開催</dt>
                  <dd>＊それぞれの立場での困りごとの洗い出し</dd>
                </dl>
                <dl>
                  <dt>分業制、役割分担制導入による滞留時間の短縮</dt>
                  <dd>
                    ＊塗装に関しては下地担当、上塗り担当、仕上げ兼バックアップ担当と分業化し、
                    <br className="sp_hide_br" />
                    <span className="sp_hide_br">　</span>
                    構内持ち込み車両の削減等スペースの有効活用
                  </dd>
                </dl>
                <dl>
                  <dt>作業進捗管理ボードの活用で見える化する</dt>
                  <dd>
                    ＊鈑金に関しては大ダメージ担当、中小ダメージ担当と分担し時間短縮やスムーズな作業進捗の実現
                  </dd>
                </dl>
                <dl>
                  <dt>整理整頓、清掃、清潔</dt>
                  <dd style={{ marginBottom: 0 }}>
                    ＊無駄な部品等の撤去による工場内の有効活用
                  </dd>
                  <dd>
                    ＊塗装ブース使用前の清掃がゴミの付着防止に有効であること
                  </dd>
                </dl>
                <BaseContainerToggleCC style={{ backgroundColor: "white" }}>
                  <p className="line_height_default">
                    長年の習慣で人流も作業も改善出来るのにしない等残念な場合も多くあります。
                    <br />
                    工場内の設備機器により機械工具の配置、作業工程の動線や一日の作業者の流れを見て効果的なご提案をしております。事実、診断後生産性がアップしたとの報告も頂き大変喜ばれております。
                  </p>
                </BaseContainerToggleCC>
              </BaseContainerToggleC>
            </BaseContainerToggle>
          </BaseContainer>
          <BaseContainer>
            <BaseContainerToggle>
              <h3
                onClick={e => {
                  handleSetIsOpenArray(e)
                }}
              >
                工場設備企画設計
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 37 19.5"
                  fill="none"
                  stroke="#00b4e5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className={
                    isOpenArray?.includes("工場設備企画設計") ? "toggle3" : ""
                  }
                >
                  <path d="M1 1l17.5 17.5M36 1L18.5 18.5" />
                </svg>
              </h3>
              <BaseContainerToggleCW
                className={
                  isOpenArray?.includes("工場設備企画設計") ? "toggle3" : ""
                }
              >
                <p className="line_height_default">
                  今日までに何社も新規工場の立ち上げと新規設備導入のご提案をいたしました。
                  <br />
                  決められた予算と建屋の空間を無駄なく利用、設備導入後のメンテナンスや動線等も考慮し、経験と実績を生かしたご提案をいたします。
                </p>
              </BaseContainerToggleCW>
            </BaseContainerToggle>
          </BaseContainer>
        </ContentSection>

        <ContentSection>
          <BaseContainer>
            <H2 id="calendar">研修カレンダー</H2>
            <GoogleCalendar>
              <iframe
                src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Asia%2FTokyo&hl=ja&showTz=0&showPrint=0&showCalendars=0&showTabs=0&showDate=1&showTitle=0&showNav=1&src=aGtzODY0M2J2bHFkdW91ZDVib2FxbnEyOTRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=amEuamFwYW5lc2UjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23039BE5&color=%2362a9cc"
                frameborder="0"
                scrolling="no"
              ></iframe>
            </GoogleCalendar>
          </BaseContainer>
        </ContentSection>

        <ContentSection>
          <BaseContainer>
            <H2 id="archive">過去の研修</H2>
            <a
              href="https://various-frill-265.notion.site/bc28c5b3dfa34f3bad75c838ee355d7d?v=78525a47711f4f19b6487da3b14e5d40"
              target="_blank"
            >
              <ArchiveBtn>研修アーカイブはこちら</ArchiveBtn>
            </a>
          </BaseContainer>
        </ContentSection>

        <ContentSection>
          <BaseContainer>
            <H2 id="corporate">企業情報</H2>
            <table>
              <tbody>
                <tr>
                  <th>商号</th>
                  <td>株式会社みゆき塗料商会</td>
                </tr>
                <tr>
                  <th>創立</th>
                  <td>1985年（昭和60年）</td>
                </tr>
                <tr>
                  <th>
                    設立
                    <br />
                    （旧）住所
                  </th>
                  <td>
                    1988年（昭和63年）4月8日
                    <br />
                    東京都昭島市緑町1丁目11番7号
                  </td>
                </tr>
                <tr>
                  <th>資本金</th>
                  <td>500万円</td>
                </tr>
                <tr>
                  <th>代表者</th>
                  <td>代表取締役 宮川　行雄</td>
                </tr>
                <tr>
                  <th>社員数</th>
                  <td>役員2名　他4名</td>
                </tr>
                <tr>
                  <th>現住所</th>
                  <td>
                    〒196-0014 東京都昭島市田中町2丁目28番31号
                    <br />
                    tel<span className="tel">042-546-1255</span>
                    <br />
                    fax<span className="fax">042-546-1228</span>
                  </td>
                </tr>
                <tr>
                  <th>新社屋</th>
                  <td>
                    新社屋 2001年（平成13年）8月10日 移転
                    <br />
                    自動車鈑金塗装工場併設
                    <br />
                    研修設備・会議室を設ける
                  </td>
                </tr>
              </tbody>
            </table>
            <ImgWrap>
              <Zoom
                zoomMargin={isTabletOrMobile ? 0 : 105}
                overlayBgColorStart={"rgba(0, 130, 230, 0.85)"}
                overlayBgColorEnd={"rgba(0, 130, 230, 0.85) 100%"}
              >
                <StaticImage
                  src="../images/img_1.jpg"
                  alt="みゆき塗料商会紹介画像"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  quality={80}
                />
              </Zoom>
              <Zoom
                zoomMargin={isTabletOrMobile ? 0 : 105}
                overlayBgColorStart={"rgba(0, 130, 230, 0.85)"}
                overlayBgColorEnd={"rgba(0, 130, 230, 0.85) 100%"}
              >
                <StaticImage
                  src="../images/img_4.jpg"
                  alt="みゆき塗料商会紹介画像"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  quality={80}
                />
              </Zoom>
              <Zoom
                zoomMargin={isTabletOrMobile ? 0 : 105}
                overlayBgColorStart={"rgba(0, 130, 230, 0.85)"}
                overlayBgColorEnd={"rgba(0, 130, 230, 0.85) 100%"}
              >
                <StaticImage
                  src="../images/img_7.jpg"
                  alt="みゆき塗料商会紹介画像"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  quality={80}
                />
              </Zoom>
              <Zoom
                zoomMargin={isTabletOrMobile ? 0 : 105}
                overlayBgColorStart={"rgba(0, 130, 230, 0.85)"}
                overlayBgColorEnd={"rgba(0, 130, 230, 0.85) 100%"}
              >
                <StaticImage
                  src="../images/img_2.jpg"
                  alt="みゆき塗料商会紹介画像"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  quality={80}
                />
              </Zoom>
              <Zoom
                zoomMargin={isTabletOrMobile ? 0 : 105}
                overlayBgColorStart={"rgba(0, 130, 230, 0.85)"}
                overlayBgColorEnd={"rgba(0, 130, 230, 0.85) 100%"}
              >
                <StaticImage
                  src="../images/img_5.jpg"
                  alt="みゆき塗料商会紹介画像"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  quality={80}
                />
              </Zoom>
              <Zoom
                zoomMargin={isTabletOrMobile ? 0 : 105}
                overlayBgColorStart={"rgba(0, 130, 230, 0.85)"}
                overlayBgColorEnd={"rgba(0, 130, 230, 0.85) 100%"}
              >
                <StaticImage
                  src="../images/img_8.jpg"
                  alt="みゆき塗料商会紹介画像"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  quality={80}
                />
              </Zoom>
              <Zoom
                zoomMargin={isTabletOrMobile ? 0 : 105}
                overlayBgColorStart={"rgba(0, 130, 230, 0.85)"}
                overlayBgColorEnd={"rgba(0, 130, 230, 0.85) 100%"}
              >
                <StaticImage
                  src="../images/img_9.jpg"
                  alt="みゆき塗料商会紹介画像"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  quality={80}
                />
              </Zoom>
              <Zoom
                zoomMargin={isTabletOrMobile ? 0 : 105}
                overlayBgColorStart={"rgba(0, 130, 230, 0.85)"}
                overlayBgColorEnd={"rgba(0, 130, 230, 0.85) 100%"}
              >
                <StaticImage
                  src="../images/img_3.jpg"
                  alt="みゆき塗料商会紹介画像"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  quality={80}
                />
              </Zoom>
              <Zoom
                zoomMargin={isTabletOrMobile ? 0 : 105}
                overlayBgColorStart={"rgba(0, 130, 230, 0.85)"}
                overlayBgColorEnd={"rgba(0, 130, 230, 0.85) 100%"}
              >
                <StaticImage
                  src="../images/img_6.jpg"
                  alt="みゆき塗料商会紹介画像"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  quality={80}
                />
              </Zoom>
            </ImgWrap>
          </BaseContainer>
        </ContentSection>

        <ContentSection>
          <BaseContainer>
            <H2 id="contact">お問い合わせ</H2>
            <ContactWrap>
              <div>
                <h3>お電話でのお問い合わせ</h3>
                <a href="tel:0425461255">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                    <circle cx="25" cy="25" r="25" fill="#28367b" />
                    <path
                      d="M36.43 30.33l-2.56-2.56a2.57 2.57 0 0 0-3.61 0l-1.84 1.85c-.41.15-2.35-.62-4.88-3.15C21 24 20.28 22 20.36 21.57l1.85-1.85a2.57 2.57 0 0 0 0-3.61l-2.56-2.55h0a2.54 2.54 0 0 0-3.6 0l-2.3 2.3a.78.78 0 0 0-.1.15c-2.25 2.57.26 8.73 6 14.47a.62.62 0 0 0 .17.11c4.25 4.19 8.7 6.6 11.73 6.6a3.57 3.57 0 0 0 2.62-.95l.05-.07 2.23-2.23h0a2.55 2.55 0 0 0-.02-3.61z"
                      fill="#fff"
                    />
                  </svg>
                  042-546-1255
                </a>
              </div>
            </ContactWrap>
            <ContactWrap2>
              <div>
                <h3>フォームからのお問い合わせ</h3>
                <form
                  action="https://getform.io/f/e23e72e4-dddf-4b1a-ad7a-bcf9a1115c9f"
                  method="POST"
                >
                  <input
                    type="text"
                    name="name"
                    placeholder="お名前"
                    required="required"
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="メールアドレス"
                    required="required"
                  />
                  <textarea
                    type="text"
                    name="message"
                    placeholder="お問い合せ内容"
                    required="required"
                    rows="5"
                  />
                  <button type="submit">送信する</button>
                </form>
                {/* <button>お問い合せフォーム</button> */}
              </div>
            </ContactWrap2>

            <IframeWrap>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.847392912298!2d139.3477548160629!3d35.70537283625356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60191fcbf91d539d%3A0x6d204b3c9be31aa3!2z44CSMTk2LTAwMTQg5p2x5Lqs6YO95pit5bO25biC55Sw5Lit55S677yS5LiB55uu77yS77yY4oiS77yT77yR!5e0!3m2!1sja!2sjp!4v1643216354407!5m2!1sja!2sjp"
                loading="lazy"
                title="地図"
              ></iframe>
            </IframeWrap>
          </BaseContainer>
        </ContentSection>

        <ContentSection>
          <BaseContainer>
            <H2 id="recruit">採用情報</H2>
            <p className="recruit_p">
              少人数ならではのアットホームな会社です。
              <br />
              私たちと一緒に働いてみませんか。
              <br />
              お気軽にお問い合わせください。
            </p>
            <RecruitWrap>
              <a href="mailto:info@miyuki-e.co.jp">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                  <circle cx="25" cy="25" r="25" fill="#28367b" />
                  <path
                    d="M34.85,34H15.15a2.87,2.87,0,0,1-2.86-2.86V18.94a2.88,2.88,0,0,1,2.86-2.86h19.7a2.88,2.88,0,0,1,2.86,2.86v12.2A2.87,2.87,0,0,1,34.85,34Z"
                    fill="#fff"
                  />
                  <path
                    d="M37.26 16.3a.85.85 0 0 0-1.18-.11l-10.53 8.75a.68.68 0 0 1-.83 0l-10.53-8.75h0a.84.84 0 0 0-1.19.11h0a.84.84 0 0 0 .1 1.18l10.52 8.75a2.37 2.37 0 0 0 1.48.48 2.31 2.31 0 0 0 1.46-.48l10.57-8.75a.85.85 0 0 0 .13-1.18z"
                    fill="#28367b"
                    stroke="#28367b"
                    strokeLinejoin="round"
                    strokeWidth=".83"
                  />
                </svg>
                info@miyuki-e.co.jp
              </a>
            </RecruitWrap>
          </BaseContainer>
        </ContentSection>
      </BgBlueGradation>
    </Layout>
  )
}

const HeroSectionWrap = styled.section`
  width: 100;
  background: linear-gradient(
    180deg,
    rgba(140, 225, 255, 1) 0%,
    rgba(30, 180, 255, 1) 100%
  );
`

const HeroSection = styled.div`
  max-width: 1300px;
  margin: 0 auto;

  > div {
    @media screen and (max-width: ${UTIL.BreakPointMobile}) {
      height: 305px !important;
    }
  }
`

const DescriptionSection = styled.section`
  width: 100%;
  padding: 100px 40px;
  background: rgb(0, 110, 210);
  background: linear-gradient(
    117deg,
    rgba(0, 110, 210, 1) 0%,
    rgba(40, 54, 123, 1) 100%
  );

  @media screen and (max-width: ${UTIL.BreakPointMobile}) {
    padding: 40px 20px;
  }
`

const Divider = styled.div`
  height: 12px;
  background: rgb(0, 180, 229);
  background: linear-gradient(
    90deg,
    rgba(0, 180, 229, 1) 0%,
    rgba(0, 130, 230, 1) 100%
  );
`

const BaseContainer = styled.div`
  max-width: ${UTIL.BaseInnerWidth};
  margin: 0 auto;

  .pc_hide_br {
    display: none;
  }

  @media screen and (max-width: 960px) {
    .sp_hide_br {
      display: none;
    }

    .pc_hide_br {
      display: block;
    }
  }

  p {
    color: rgba(230, 243, 255, 1);
    font-size: ${FONT.Size15};
    animation-duration: ${UTIL.FadeInSpeed};
    line-height: 2.4;
    letter-spacing: 4px;

    &.line_height_default {
      line-height: inherit;
      letter-spacing: 2px;
    }

    &.recruit_p {
      font-size: ${FONT.Size14};
      letter-spacing: 2px;
    }

    &.fade-in-image {
      animation: fadeIn ${UTIL.FadeInSpeed};
      -webkit-animation: fadeIn ${UTIL.FadeInSpeed};
      -moz-animation: fadeIn ${UTIL.FadeInSpeed};
      -o-animation: fadeIn ${UTIL.FadeInSpeed};
      -ms-animation: fadeIn ${UTIL.FadeInSpeed};

      max-width: 960px;
      margin: 0 auto;
    }

    @media screen and (max-width: ${UTIL.BreakPointMobile}) {
      font-size: ${FONT.Size14};
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  table {
    max-width: 584px;
    margin: 0 auto;

    th {
      font-weight: normal;
      vertical-align: top;
    }

    th,
    td {
      text-align: left;
      font-size: ${FONT.Size14};
      padding: 12px;
      border: none;
      letter-spacing: 1px;

      @media screen and (max-width: ${UTIL.BreakPointMobile}) {
        font-size: ${FONT.Size12};
        padding: 10px 12px 10px 0;
      }
    }

    td {
      span {
        &.tel {
          padding-left: 12px;
        }

        &.fax {
          padding-left: 8px;
        }
      }

      @media screen and (max-width: ${UTIL.BreakPointMobile}) {
        padding-right: 0;
      }
    }
  }
`

const BaseContainerMaker = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const BgBlueGradation = styled.div`
  width: 100%;
  background-attachment: fixed !important;
  background: rgb(140, 225, 255);
  background: linear-gradient(
    180deg,
    rgba(140, 225, 255, 1) 0%,
    rgba(30, 180, 255, 1) 100%
  );
`

const ContentSection = styled.section`
  padding: 145px 40px 120px;
  text-align: center;

  @media screen and (max-width: ${UTIL.BreakPointMobile}) {
    padding: 60px 20px 50px;
  }

  + section {
    padding-top: 0;
  }

  p {
    color: ${COLORS.SecondaryBlue};
    font-size: ${FONT.Size17};

    @media screen and (max-width: ${UTIL.BreakPointMobile}) {
      font-size: ${FONT.Size14};
    }
  }
`

const C3C2 = styled.div`
  width: calc((100%) / 3);

  @media screen and (max-width: ${UTIL.BreakPointMobile}) {
    width: calc((100%) / 2);
  }

  + div {
    @media screen and (max-width: ${UTIL.BreakPointMobile}) {
      padding-left: 0;
    }
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(8),
  &:nth-child(9) {
    padding-left: 7%;
  }

  @media screen and (max-width: ${UTIL.BreakPointMobile}) {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(8),
    &:nth-child(9) {
      padding-left: 0;
    }
    &:nth-child(even) {
      padding-left: 3%;
    }
  }

  li {
    color: ${COLORS.SecondaryBlue};
    text-align: left;
    font-size: ${FONT.Size14};
    margin-bottom: 8px;

    @media screen and (max-width: ${UTIL.BreakPointMobile}) {
      font-size: ${FONT.Size12};
    }
  }
`

const H3 = styled.h3`
  width: 100%;
  color: ${COLORS.SecondaryBlue};
  font-weight: normal;
  font-size: ${FONT.Size18};
  padding: 8px;
  text-align: center;
  background-color: #fff;
  font-weight: 600;

  @media screen and (max-width: ${UTIL.BreakPointMobile}) {
    font-size: ${FONT.Size14};
    padding: 8px 4px;
  }
`

const H2 = styled.h2`
  color: ${COLORS.PrimaryBlue};
  font-weight: normal;
  font-size: ${FONT.Size27};
  padding-bottom: 12px;
  font-weight: 600;

  @media screen and (max-width: ${UTIL.BreakPointMobile}) {
    font-size: ${FONT.Size22};
  }
`

const BaseContainerToggle = styled.div`
  h3 {
    cursor: pointer;
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 50px;
    color: white;
    font-size: ${FONT.Size22};
    letter-spacing: 2px;
    background-color: ${COLORS.SecondaryBlue};
    font-weight: normal;
    text-align: center;
    margin-bottom: 0;

    @media screen and (max-width: ${UTIL.BreakPointMobile}) {
      font-size: ${FONT.Size19};
      margin-top: 32px;
    }
  }

  svg {
    position: absolute;
    right: 22px;
    width: 30px;
    top: 41%;
    /* transition: all 0.05s ease; */
    transform: rotateZ(0deg);

    @media screen and (max-width: ${UTIL.BreakPointMobile}) {
      right: 16px;
    }

    &.toggle3,
    &.toggle2,
    &.toggle1 {
      transform: rotateZ(180deg);
    }
  }
`

const BaseContainerToggleC = styled.div`
  background-color: #c9efff;
  padding: 40px 36px;
  text-align: left;
  max-height: 0px;
  display: none;

  &.toggle1,
  &.toggle2 {
    max-height: 1500px;
    display: block;
  }

  h4 {
    font-size: ${FONT.Size19};
    font-weight: 600;
    color: ${COLORS.PrimaryBlue};
    margin-bottom: 6px;
    margin-bottom: 16px;
    line-height: 1.4;
  }

  p {
    color: ${COLORS.SecondaryBlue};
    font-size: ${FONT.Size14};
    margin-bottom: 0;
  }

  dl {
    h4 {
      font-size: ${FONT.Size16};
      color: ${COLORS.SecondaryBlue};
      font-weight: normal;
      margin-bottom: 8px;
    }

    dt {
      color: ${COLORS.PrimaryBlue};
      padding-bottom: 8px;
      margin-bottom: 16px;
      border-bottom: 1px solid ${COLORS.PrimaryBlue};
      font-size: ${FONT.Size16};
      font-weight: 600;
      display: inline-block;
      letter-spacing: 1px;
    }

    dd {
      color: ${COLORS.SecondaryBlue};
      font-size: ${FONT.Size13};
      margin-bottom: 36px;
      letter-spacing: 1px;
    }
  }
`

const BaseContainerToggleCC = styled.div`
  background-color: white;
  padding: 32px;

  @media screen and (max-width: ${UTIL.BreakPointMobile}) {
    padding: 22px;
  }

  p {
    text-align: left;
    color: ${COLORS.SecondaryBlue};
    font-size: ${FONT.Size14};
    margin-bottom: 0;
  }
`
const BaseContainerToggleCW = styled.div`
  background-color: white;
  overflow: hidden;
  max-height: 1500px;
  transition: max-height 0.4s ease-in-out;
  max-height: 0px;
  display: none;

  &.toggle3 {
    max-height: 1500px;
    display: block;
  }

  p {
    text-align: left;
    color: ${COLORS.SecondaryBlue};
    font-size: ${FONT.Size14};
    margin: 48px 32px;
  }
`

const ImgWrap = styled.div`
  display: flex;
  margin-top: 50px;
  flex-wrap: wrap;
  column-gap: 4px;

  @media screen and (max-width: ${UTIL.BreakPointMobile}) {
    margin-top: 16px;
  }

  > div {
    margin-top: 4px;
    width: calc(100% / 3 - 3px);
  }

  @media screen and (max-width: ${UTIL.BreakPointMobile}) {
    > div {
      width: 100%;
    }
  }
`

const ContactWrap = styled.div`
  display: flex;
  justify-content: space-around;

  h3 {
    font-weight: normal;
    color: ${COLORS.SecondaryBlue};
    font-size: ${FONT.Size19};
  }

  button {
    padding: 12px 40px;
    color: white;
    background-color: ${COLORS.PrimaryBlue};
    font-size: ${FONT.Size23};
    border: none;
  }

  a {
    color: ${COLORS.SecondaryBlue};
    font-size: ${FONT.Size27};
    vertical-align: sub;

    @media screen and (max-width: ${UTIL.BreakPointMobile}) {
      vertical-align: top;
      font-size: ${FONT.Size24};
    }

    svg {
      width: 50px;
      margin-right: 24px;

      @media screen and (max-width: ${UTIL.BreakPointMobile}) {
        width: 42px;
        margin-right: 12px;
      }
    }
  }
`

const ContactWrap2 = styled.div`
  margin-top: 60px;

  input:focus,
  textarea:focus {
    border: 2px solid ${COLORS.SecondaryBlue};
  }

  input,
  textarea,
  button {
    margin-bottom: 12px;
    width: 100%;
    display: block;
    border: none;
    padding: 12px 20px;
    font-size: ${FONT.Size14};
    border-radius: 4px;
    border: 2px solid transparent;
  }

  button {
    background-color: ${COLORS.SecondaryBlue};
    color: #fff;
    font-weight: bold;
  }

  input {
  }

  h3 {
    font-weight: normal;
    color: ${COLORS.SecondaryBlue};
    font-size: ${FONT.Size19};
  }
`

const IframeWrap = styled.div`
  margin-top: 70px;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    border: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
`

const RecruitWrap = styled.div`
  a {
    color: ${COLORS.SecondaryBlue};
    font-size: ${FONT.Size27};
    vertical-align: sub;

    @media screen and (max-width: ${UTIL.BreakPointMobile}) {
      vertical-align: top;
      font-size: ${FONT.Size24};
    }

    svg {
      width: 50px;
      margin-right: 24px;

      @media screen and (max-width: ${UTIL.BreakPointMobile}) {
        width: 42px;
        margin-right: 12px;
      }
    }
  }
`

const Top = styled.span`
  position: fixed;
  font-size: ${FONT.Size15};
  font-family: "Avenir Next", "Segoe UI", Helvetica, sans-serif;
  cursor: pointer;
  z-index: 3;
  color: #fff;
  bottom: 30px;
  right: 30px;
  letter-spacing: 1px;

  svg {
    width: 30px;
    vertical-align: middle;
    margin-left: 12px;
  }

  @media screen and (max-width: ${UTIL.BreakPointMobile}) {
    bottom: 16px;
    right: 16px;

    span {
      display: none;
    }
  }
`

const ArchiveBtn = styled.button`
  padding: 12px 40px;
  color: white;
  background-color: ${COLORS.PrimaryBlue};
  font-size: ${FONT.Size18};
  border: none;
  cursor: pointer;
`

const GoogleCalendar = styled.div`
  width: 100%;

  iframe {
    width: 100%;
    height: 500px;
  }
`

export default IndexPage
